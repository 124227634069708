class Farbwechsler {
    constructor({ trigger, text, containerText, slider, buttons }) {
        this.trigger = trigger ? document.querySelector(trigger) : null;
        this.text = text ? document.querySelector(text) : null;
        this.containerText = containerText ? document.querySelector(containerText) : null;
        this.slider = slider ? document.querySelector(slider) : null;
        this.buttons = buttons ? document.querySelectorAll(buttons) : null;

        this.setupMediaQuery();
        window.addEventListener('resize', this.setupMediaQuery.bind(this));
    }

    setupMediaQuery() {
        const isDesktopNow = window.matchMedia('(min-width: 1000px)').matches;

        // Nur wenn sich der Zustand ändert
        if (isDesktopNow !== this.isDesktop) { 
            this.isDesktop = isDesktopNow;

            if (!this.isDesktop) {
                this.removeAnimations();
                console.log("removed");
            } else {
                this.addAnimations(); // Hier wird addAnimations aufgerufen, wenn der Bildschirm Desktop-Größe hat
                console.log("added");
            }
        }
    }

    removeAnimations() {
        if (gsap.timeline) {
            gsap.timeline().kill();
        }

        if (this.buttons) {
            this.buttons.forEach((button) => {
                if (gsap.isTweening(button)) {
                    gsap.killTweensOf(button);
                }
            });
        }
    }

    addAnimations() {
        this.farbeWechselnTextMitContainerUndSlider();
        this.farbeWechselnTextMitSlider();
    }

    farbeWechselnTextMitContainerUndSlider() {
        if (!this.isDesktop || !this.trigger || !this.text || !this.containerText || !this.slider) return;

        gsap.timeline({
            scrollTrigger: {
                trigger: this.trigger,
                start: () => `${((this.text.getBoundingClientRect().top + window.scrollY + this.containerText.getBoundingClientRect().height) - this.sliderWidth) * -1} top`,
                end: () => `${(this.text.getBoundingClientRect().top - this.sliderWidth) * -1} top`,
                scrub: true,
                invalidateOnRefresh: true,
            }
        }).to(this.containerText, {
            immediateRender: true,
            ease: 'none',
            backgroundPositionY: '100%'
        }, 0);
    }

    farbeWechselnTextMitSlider() {
        if (!this.isDesktop || !this.trigger || !this.buttons || !this.slider) return;

        let sliderWidth = this.slider.clientWidth - window.innerWidth;

        this.buttons.forEach((button) => {
            gsap.to(button, {
                scrollTrigger: {
                    trigger: this.trigger,
                    start: () => `${(button.getBoundingClientRect().top + button.getBoundingClientRect().height - sliderWidth) * -1} top`,
                    end: () => `${(button.getBoundingClientRect().top - sliderWidth) * -1} top`,
                    scrub: true,
                    invalidateOnRefresh: true,
                },
                backgroundPositionY: '100%',
                ease: 'none',
            });
        });
    }
}