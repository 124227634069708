gsap.registerPlugin(ScrollTrigger);

class HorizontalScrollController {
    constructor(containerSelector, menueItemSelector, sectionSelector, pinScrolltrigger) {
        this.containerSelector = containerSelector;
        this.menueItemSelector = menueItemSelector;
        this.sectionSelector = sectionSelector;
        this.pinScrolltrigger = pinScrolltrigger;

        this.pinWrap = document.querySelector(this.containerSelector);
        this.scrollingContainer = this.pinWrap;

        this.scrollingContainer = this.pinWrap;
        this.menueItems = Array.from(document.querySelectorAll(this.menueItemSelector));
        this.sections = gsap.utils.toArray(this.sectionSelector);
        this.sectionsMap = new Map();
        this.itemsMap = new Map();

        this.setupSections();
        this.setupMenueClick();
    }

    initHorizontalScroll() {
        if (this.pinWrap != null) {
            this.setupHorizontalScroll();
            window.addEventListener("resize", () => {
                this.refresh();
            });
        }
    }

    // vermeidet Lücken bei der Grössenänderung
    refresh() {
        this.pinWrap = document.querySelector(this.containerSelector);
        this.horizontalScrollLength = this.pinWrap.offsetWidth - window.innerWidth;
    }

    setupHorizontalScroll() {
        // Initialberechnung der horizontalen Scrolllänge
        this.horizontalScrollLength = this.pinWrap.offsetWidth - window.innerWidth;

        this.horizontalScrollTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: this.containerSelector,
                start: "top top",
                end: () => `+=${this.horizontalScrollLength}`,
                pin: this.pinScrolltrigger,
                invalidateOnRefresh: true,
                scrub: true,
                pinSpacing: true,
                onUpdate: self => {
                    const activeMenueItemSelector = self.progress === 1 ? `${this.menueItemSelector}--entstehung` : `${this.menueItemSelector}--konzept`;
                    const menueItemElement = document.querySelector(activeMenueItemSelector);
                    if (menueItemElement) {
                        this.setActiveMenueItem(menueItemElement);
                    }
                }
            }
        });

        this.horizontalScrollTimeline.to(this.scrollingContainer, {
            x: () => -this.horizontalScrollLength,
            ease: 'none',
        });
    }

    setupSections() {
        this.sections.forEach((section, index) => {
            this.sectionsMap.set(section, this.menueItems[index]);
            this.itemsMap.set(this.menueItems[index], section);
        });

        this.sections.forEach((section, i) => {
            ScrollTrigger.create({
                trigger: section,
                start: "top center",
                end: "bottom center",
                onEnter: () => {
                    if (!section.matches(this.sectionSelector)) return;
                    this.setActiveMenueItem(this.sectionsMap.get(section));
                },
                onLeaveBack: () => {
                    if (!section.matches(this.sectionSelector)) return;
                    this.setActiveMenueItem(this.sectionsMap.get(this.sections[i - 1]));
                }
            });
        });
    }

    setupMenueClick() {
        const header = document.querySelector(".header__element--projekt");
        this.menueItems.forEach((item) => {
            item.addEventListener("click", () => {
                const targetSection = this.itemsMap.get(item);
                if (targetSection) {
                    this.scrollToSection(targetSection);
                } else {
                    console.error(`Section nicht gefunden: ${item}`);
                }
            });
        });
    }

    setActiveMenueItem(element) {
        this.menueItems.forEach((item) => {
            if (element === item) {
                item.setAttribute("data-menue-projekt", "aktiv");
                item.removeAttribute("data-grain");
            } else {
                item.removeAttribute("data-menue-projekt");
                item.setAttribute("data-grain", "aktiv");
            }
        });
    }

    scrollToSection(section) {
        const paddingTop = parseInt(window.getComputedStyle(section).paddingTop);
        let offsetTop = section.offsetTop - paddingTop;

        // Zustand: Bau & Konzept
        if (section.matches('#konzept')) {
            if (document.querySelector("#konzept")) {
                offsetTop = document.querySelector("#konzept").offsetTop - paddingTop;
            }
        }
        // Zustand: Bau & Konzept & Entstehung
        else if (section.matches('#entstehung')) {
            if (document.querySelector("#konzept") && document.querySelector("#entstehung")) {
                offsetTop = document.querySelector("#konzept").offsetTop + this.pinWrap.offsetWidth - paddingTop;
            }
        }
        // Zustand: Bau & Entstehung
        else if (section.matches('#bau-entstehung')) {
            if (document.querySelector("#entstehung")) {
                offsetTop = document.querySelector("#entstehung").offsetTop - paddingTop;
            }
        }

        window.scrollTo({
            top: offsetTop,
            behavior: "smooth"
        });
    }
}